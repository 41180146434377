import React, { useState } from 'react';
import './Forma.css';
import Form from './../../images/form.png';
import ReCAPTCHA from "react-google-recaptcha";


const Forma = () => {
    const [isChecked, setIsChecked] = useState(false);
    const captchaCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <main className="main" id="mainForm">
                <div className="wrap__form__obrs__and__contacts">
                    <div className="wrap-forma">

                        <form id="forma" data-wow-delay="0.3s" action="./../../php/sender.php" method="post"
                              className="wow animate__animated animate__fadeInUp">

                            <legend>Форма обратной связи</legend>
                            <br/>

                            <br/><br/>
                            <input type="text" className="form-control" name="name" placeholder="Тема обращения"
                                   required/>
                            <input type="text" className="form-control" name="surname" placeholder="ФИО заявителя"
                                   required/>
                            <input type="email" className="form-control" name="email" placeholder="Почта заявителя"
                                   required/>
                            <input type="text" className="form-control" name="phone" placeholder="Телефон"/>

                            <textarea className="form-control" name="text" rows="5"
                                      placeholder="Сообщение"></textarea>


                            <div className="subm">
                                <br/>


                                <ReCAPTCHA
                                    sitekey="6LeleJQqAAAAABz1YucF1x0uW6bDlcl5GKoDVKf8"
                                    onChange={captchaCheckboxChange}
                                />
                                <br/>

                                <button type="submit" name="submit" className="submit send-form btn"
                                        disabled={!isChecked}> Отправить форму
                                </button>

                            </div>

                            <br/>
                            <div className="wrapp-small">
                                <small>Нажимая кнопку «Отправить», вы даёте свое
                                    согласие на сбор и обработку
                                    моих персональных данных</small><br/>
                            </div>
                            <div className="status"></div>
                        </form>
                        <img id="imgform" src={Form} alt=""/>
                    </div>

                </div>


            </main>
        </>
    );
};

export default Forma;
