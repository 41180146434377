import React from 'react';
import './Footer.css';
const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer-wraper">
                    <div className="top-footer-wrap">
                        <div>
                            <h6 className="title-footer">Контакты</h6>
                            <ul className="footer-list">
                                <li className="footer-item">
                                    <a href="#form" className="footer-link">Обратная связь</a>
                                </li>
                                <li className="footer-item">
                                    <a href="" className="footer-link">73-00-30/8-905-349-0030</a>
                                </li>
                                <li className="footer-item">
                                    <a href="" className="footer-link">domok73@bk.ru</a>
                                </li>

                            </ul>
                        </div>
                        <div>
                            <br/><br/><br/>
                            <h6 className="title-footer">Соц. сети</h6>
                            <ul className="footer-list">
                                <li className="footer-item">
                                    <a href="https://vk.com/id822411566" className="footer-link">Вконтакте</a>
                                </li>

                            </ul>
                        </div>
                        <div>
                            <h6 className="title-footer">Юридический адрес</h6>
                            <ul className="footer-list">
                                <li className="footer-item">
                                    <a href="" className="footer-link">432030</a>
                                </li>
                                <li className="footer-item">
                                    <a href="" className="footer-link">Ульяновск</a>
                                </li>
                                <li className="footer-item">
                                    <a href="" className="footer-link">проезд Лесной, д.6, стр.1</a>
                                </li>
                                <li className="footer-item">
                                    <a href="" className="footer-link">офис 10/1</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h6 className="title-footer">Информация о УК</h6>
                            <ul className="footer-list">
                                <li className="footer-item">
                                    <a href="" className="footer-link">ИНН: 7325176306</a>
                                </li>
                                <li className="footer-item">
                                    <a href="" className="footer-link">КПП: 732501001</a>
                                </li>
                                <li className="footer-item">
                                    <a href="" className="footer-link">ОГРН: 1227300001961</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr className="footer-hr"/>

                    <div className="bottom-footer">
                        <span className="copyright">nocopyright &copy; 2024 Дом'ОК</span>

                    </div>

                </div>
            </footer>

        </>
    );
};

export default Footer;