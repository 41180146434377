import React from 'react';
import index_main_banner from './../../images/index_main_banner.png';
import './Banner.css';
import './../../../src/Project__Styles/fonts__project.css';
const Banner = () => {
    return (
        <main className="Banner">
            <div className="wrap__image__banner">
                <div className="Banner__image"> </div>
            </div>
        </main>
    );
};

export default Banner;