import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import Main from "./components/Main";
import Footer from "../src/components/Footer/Footer";
import Header from "./components/Header/Header";
import House__management__company from "./components/house__management__company/house__management__company";
import AboutCompany from "./components/About__company/About__company";


function App() {
  return (
            <Router>
                <div className="app-container">
                    <Header />
                    <Routes>
                        <Route exact path="/" element={<Main />} />
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <Route path="/Houses" element={<House__management__company />} />
                        <Route path="/About" element={<AboutCompany />} />
                        <Route path="*" element={<Navigate to="/" />} />

                    </Routes>

                    <Footer />
                </div>
            </Router>
  );
}

export default App;
