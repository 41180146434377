import React from 'react';
import './About__company.css';
import Evgrafov from '../../images/EvgrafovS.V.jpg';
const AboutCompany = () => {
    return (
        <div>

            <main className="main__about">
                <div className="wrap-label">
                    <h1 className="title animate__animated animate__fadeInDown">Информация о
                        управляющей компании</h1><br/><br/><br/>
                </div>
                <div className="col-md-6 col-sm-12 boxed-inverse animate__animated animate__fadeIn">
                    <h4 className="text-color">Полное наименование</h4>
                    <p className="text-s">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ДОМ' ОК"</p>
                    <hr className="space s"/>
                    <h4 className="text-color">Сокращенное наименование</h4>
                    <p className="text-s">ООО "ДОМ' ОК"</p>

                    <hr className="space s"/>
                    <h4 className="text-color">Юридический адрес</h4>
                    <p className="text-s">432030, Ульяновская обл, Ульяновск г, проезд Лесной,д.6, стр.1, офис 10/1</p>

                    <hr className="space s"/>
                    <h4 className="text-color">Телефон</h4>
                    <p className="text-s">73-00-30/8-905-349-0030</p>

                    <hr className="space s"/>
                    <h4 className="text-color">E-mail</h4>
                    <p className="text-s">domok73@bk.ru</p>

                    <hr className="space s"/>
                    <h4 className="text-color">ИНН</h4>
                    <p className="text-s">7325176306</p>

                    <hr className="space s"/>
                    <h4 className="text-color">КПП</h4>
                    <p className="text-s">732501001</p>

                    <hr className="space s"/>
                    <h4 className="text-color">ОГРН</h4>
                    <p className="text-s">1227300001961</p>

                    <hr className="space s"/>
                    <h4 className="text-color">ОКПО</h4>
                    <p className="text-s">99112282</p>

                    <hr className="space s"/>
                    <h4 className="text-color">Расчётный счет</h4>
                    <p className="text-s">40702810129280006729</p>

                    <hr className="space s"/>
                    <h4 className="text-color">Корреспондентский счет</h4>
                    <p className="text-s">30101810200000000824</p>

                    <hr className="space s"/>
                    <h4 className="text-color">БИК</h4>
                    <p className="text-s">042202824</p>

                    <hr className="space s"/>
                    <h4 className="text-color">Банк</h4>
                    <p className="text-s">Филиал «Нижегородский» АО «АЛЬФА-БАНК»</p>

                    <hr className="space s"/>
                    <h4 className="text-color">Директор</h4>
                    <img src={Evgrafov}/>
                    <p className="text-s">Евграфов Сергей Владимирович, действует на основании Устава</p>

                    <hr className="space s"/>
                    <h4 className="text-color">Телефон директора</h4>
                    <p className="text-s">8-904-191-7690<br/>8-905-349-0030</p>


                </div>
            </main>
        </div>
    );
};

export default AboutCompany;